// routes.ts
export const routes = [
  { path: "/", component: "LandingPage" },
  { path: "/privacy", component: "PrivacyPolicy" },
  { path: "/contact", component: "ContactForm" },
  { path: "/tutorials", component: "TutorialsPage" },
  { path: "/terms", component: "TermsAndConditions" },
  // { path: "/login", component: "Login" },
  { path: "/tutorialcontent/:documentId", component: "TutorialContent" },
  { path: "/dashboard", component: "Home", protected: true },
  // { path: "/upload", component: "OcrSearch", protected: true },
  { path: "/settings", component: "UserSettingsPage", protected: true },
];

// Create a map to resolve folder paths
export const componentPathMap = {
  LandingPage: "landing",
  PrivacyPolicy: "privacy",
  // Login: "login",
  TutorialContent: "tutorialcontent",
  Home: "home",
  // OcrSearch: "search",
  UserSettingsPage: "settings",
  ContactForm: "contact",
  TutorialsPage: "tutorials",
  TermsAndConditions: "terms",
};