import { Mountain, LogOut } from "lucide-react";
import { Button } from "../../components/UI/button"
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


export default function Header() {
  const { logout } = useAuth0();

  const handleLogout = () => {
    // Add your logout logic here
     logout({
      logoutParams: { returnTo: window.location.origin },
    });
  
  }

  return (
    <header className="px-6 h-16 flex items-center justify-between shadow-lg">
       <div className="flex items-center justify-between h-16">
          <a href="/dashboard" className="flex items-center space-x-2">
            <img
              src="/logo.png"
              alt="InboxLlama logo"
              className="w-auto h-10"
            />
            <span className="text-xl font-bold text-gray-900 mt-2">InboxLlama</span>
          </a>
        </div>
      {/* <a className="flex items-center text-white space-x-2" href="/">
        <span className="font-bold text-xl tracking-tight text-black">
          InboxLlama
        </span>
      </a> */}
      <Button
        variant="ghost"
        size="sm"
        onClick={handleLogout}
        className="text-black hover:text-gray-700"
      >
        <LogOut className="h-5 w-5 mr-2" />
        Logout
      </Button>
    </header>
  )
}
