// src/App.tsx
import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import AppLayout from "pages/AppLayout/page";
import { routes, componentPathMap } from "./routes";
import { useAuth0 } from "@auth0/auth0-react";
// Dynamic component loader using componentPathMap
const loadComponent = (componentName:string) => {
  const folderName = componentPathMap[componentName]; // Get folder name from map
  return React.lazy(() => import(`./pages/${folderName}/page`)); // Import dynamically using folder name
};

// ProtectedRoute checks if the user is authenticated
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently,user } = useAuth0();
  if (isAuthenticated) {
    getAccessTokenSilently({detailedResponse: true,
      audience: 'https://dev-rjkgvo1v5uk0v8ok.us.auth0.com/api/v2/',  
      scope: 'read:messages'}as any).then((token: any) => {
      localStorage.setItem("access_token", token.id_token);
    });
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return isAuthenticated ? children : <Navigate to="/" />;
};

function App() {
  // const { getAccessTokenSilently } = useAuth0();
  // const getToken = async () => {
  //   try {
  //     const token = await getAccessTokenSilently({
  //       detailedResponse: true,
  //       audience: 'https://dev-rjkgvo1v5uk0v8ok.us.auth0.com/api/v2/',  
  //       scope: 'read:messages', 
  //     }as any);
  //     console.log('token: ', token);
      
  //     // Send this token to your backend for verification
  //   } catch (error) {
      
  //   }
  // };
  // getToken()

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map(({ path, component, protected: isProtected }, index) => {
            const Component = loadComponent(component);

            if (isProtected) {
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <Component />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />
              );
            }

            return <Route key={index} path={path} element={<Component />} />;
          })}
          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;