import Header from "@components/UI/Header";
import { Button } from "../../components/UI/button"
import { Mails, Trash2, Upload, Settings, LogOut } from "lucide-react"
import { Link } from "react-router-dom";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <html lang="en">
      <body>
        <div className="flex h-screen">
          {/* Sidebar */}
          <aside className="w-16 bg-white shadow-md flex flex-col items-center pb-4 space-y-6 pt-20">
            <Link to="/dashboard">
              <Mails className="text-gray-600" />
            </Link>
            {/* <Link to="/trash">
              <Trash2 className="text-gray-600" />
            </Link> */}
            {/* <Link to="/upload">
              <Upload className="text-gray-600" />
            </Link> */}
            <Link to="/settings">
              <Settings className="text-gray-600" />
            </Link>
          </aside>

          {/* Main Content */}
          <div className="flex-1 flex flex-col">
            {/* Header */}
            <Header/>
            {/* <header className="bg-white shadow-sm h-16 flex items-center justify-between px-6">
              <h1 className="text-xl font-semibold">InboxLlama</h1>
              <Button variant="ghost" size="sm">
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </header> */}

            {/* Content Area */}
            <main className="flex-1 overflow-x-hidden overflow-y-auto  p-6">
          {children}
        </main>
          </div>
        </div>
      </body>
    </html>
  )
}